import SYSTEM_CONSTANTS from '../common/constants';
import { Icon, IconAttributes, QueryParams, ResponseData } from '../common/define-types';
import HttpClient from './http-client';
import { AjaxError } from 'rxjs/ajax';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';



export interface Production {
  Name: string;
  Description: string;
  Status: boolean;
  Price: number;
  Type: string | null;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  Code: string;
  Title: string;
  Sort: number;
  Image?: {
    data: IconAttributes;
  };
  id?: number;
}

export default class ProductionsAPI {
  static host = SYSTEM_CONSTANTS.HOST;
  static get(query: QueryParams): Observable<ResponseData<Production> | null> {
    let api = `${ProductionsAPI.host}/${SYSTEM_CONSTANTS.PRODUCTION.GET}?populate=Image`;
    console.log(query);
    let menusId = query['menuId'];
    if (menusId) {
      api = `${api}&filters[menus][id][$eq]=${menusId}`;
    }
    if (query['id']) {
      api = `${api}&filters[id][$eq]=${query['id']}`;
    }
    if (query['tagId']) {
      api = `${api}&filters[tags][id][$eq]=${query['tagId']}`;
    }
    if (query.page) {
      api = `${api}&pagination[page]=${query.page}`;
    }
    if (query.size) {
      api = `${api}&pagination[pageSize]=${query.size}`;
    }
    
    if (query.search && query.search.length > 0) {
      api = `${api}&filters[$or][0][Title][$contains]=${query.search}&filters[$or][1][Name][$contains]=${query.search}&filters[$or][2][Code][$contains]=${query.search}`;
    }
    return HttpClient.get(api, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    }).pipe(
      map(
        res => (res as ResponseData<Production>) || null,
        catchError((e: AjaxError) => throwError(e)),
      ),
    );
  }
}
