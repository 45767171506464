import React, { useEffect, useState } from 'react';
import { Avatar, Row, Col, Input, Button, Typography, Card } from 'antd';
import { useHistory } from 'react-router-dom';
import './PortraitGallery.css';

import CompanyFooter from '../../footer/CompanyFooter';
import child1 from '../../../image/avatar/child1.png';
import child2 from '../../../image/avatar/child2.png';
import child3 from '../../../image/avatar/child3.png';
import child4 from '../../../image/avatar/child4.png';
import child5 from '../../../image/avatar/child5.png';
import child6 from '../../../image/avatar/child6.png';
import child7 from '../../../image/avatar/child7.png';
import child8 from '../../../image/avatar/child8.png';
import child9 from '../../../image/avatar/child9.png';
import Banner from './Banner/Banner';
import { useDispatchRoot, useSelectorRoot } from '../../../store/store';
import { getChilds } from '../../../store/controls/Child.slice';
import SYSTEM_CONSTANTS from '../../../common/constants';

const { Title } = Typography;
const { Search } = Input;

const students = [
  { name: 'Student 1', image: child1 },
  { name: 'Student 2', image: child2 },
  { name: 'Student 3', image: child3 },
  { name: 'Student 4', image: child4 },
  { name: 'Student 5', image: child5 },
  { name: 'Student 6', image: child6 },
  { name: 'Student 7', image: child7 },
  { name: 'Student 8', image: child8 },
  { name: 'Student 9', image: child9 },
];

const PortraitGallery = () => {
  const history = useHistory();
  const dispath = useDispatchRoot();
  const {ChildsData} = useSelectorRoot(state => state.Child);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [filteredStudents, setFilteredStudents] = useState(ChildsData?.data.map(item => ({...item.attributes, id: item.id})) || []);
  useEffect(() => {
    let students = ChildsData?.data.map(item => ({...item.attributes, id: item.id})) || [];
    setFilteredStudents(students.filter(student => student.Name.toLowerCase().includes(searchQuery.toLowerCase())));
  }, [ChildsData]);
  useEffect(() => {
    dispath(getChilds());
  },[]);
  return (
    <div className="portrait-gallery">
      <Banner title={'CHÂN DUNG'}></Banner>

      {/* Search Bar */}
      <Row justify="center" className="search-bar">
        <Col xs={24} sm={12} md={8}>
          <Search placeholder="Nhập tên học viên" size="large" style={{margin: 0}} onChange={(e) => setSearchQuery(e.target.value)} />
        </Col>
      </Row>

      {/* Student Portraits */}
      <Row gutter={[16, 16]} justify="center" className="portraits">
        {filteredStudents.map((student, index) => (
          <Col key={index} xs={24} sm={12} md={8} lg={6}>
            <div className="student-card" onClick={() => {
       history.push('/child/'+student.id);
      }}>
              <Avatar size={128} src={SYSTEM_CONSTANTS.HOST + student.Avatar?.data.attributes.url} alt={student.Name} className="student-avatar" />
              <Title level={5} className="student-name">{student.Name}</Title>
            </div>
          </Col>
        ))}
      </Row>

      {/* Load More Button */}
      {/* <Row justify="center" className="load-more">
        <Button type="primary" size="large">Xem thêm</Button>
      </Row> */}
      <CompanyFooter />
    </div>
  );
};

export default PortraitGallery;
