import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Pagination, QueryParams, ResponseData, RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError, map, of, OperatorFunction} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import PostsAPI, { Post } from "../../api/Post";
import { set } from "date-fns";

interface PostsState {
    loading: boolean;
    PostsData: ResponseData<Post> | null;
    newData: Post | null;
    errorMessage: string | null;
    pagination: Pagination | null | undefined;
}

const initState: PostsState = {
    loading: false,
    PostsData: null,
    newData: null,
    errorMessage: null,
    pagination: null
};
const PostsSlice = createSlice({
    name: 'Posts',
    initialState: initState,
    reducers: {
        getPosts(state, action: PayloadAction<{category?: string, tag?: string}>) {
            state.loading = true;
        },
        getPost(state, action: PayloadAction<{id?: string}>) {
            state.loading = true;
        },
        setPost(state, action: PayloadAction<Post | null>) {
            state.loading = false;
            state.newData = action.payload;
        },
        getPostsSuccess(state, action: PayloadAction<ResponseData<Post> | null>) {
            state.loading = false;
            state.PostsData = action.payload;
            state.pagination = action.payload?.meta?.pagination;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const Posts$: RootEpic = (action$) =>
    action$.pipe(
        filter(getPosts.match),
        switchMap((re) => {
            return PostsAPI.get({category: re.payload.category, tag: re.payload.tag}).pipe(
                mergeMap((res) => {
                    return [
                        PostsSlice.actions.getPostsSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    PostsSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const Post$: RootEpic = (action$) =>
    action$.pipe(
        filter(getPost.match),
        switchMap((re) => {
            console.log(re.payload.id)
            return PostsAPI.get({id: re.payload.id}).pipe(
                mergeMap((res) => {
                    return [
                        PostsSlice.actions.setPost(res?.data[0].attributes || null),
                    ];
                }),
                catchError((e: AjaxError) => [
                    PostsSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const PostsEpics = [Posts$,  Post$];;
export const {
    getPosts,
    getPostsSuccess,
    getPost,
} = PostsSlice.actions;
export const PostsReducer = PostsSlice.reducer;