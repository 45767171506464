import React, { useEffect } from 'react';
import { Card, Col, Row, Typography } from 'antd';
import './LatestNews.css';
import news1 from '../../../../image/news/news1.png'
import news2 from '../../../../image/news/news2.png'
import news3 from '../../../../image/news/news3.png'
import news4 from '../../../../image/news/news4.png'

import news5 from '../../../../image/news/news5.png'
import news6 from '../../../../image/news/news6.png'
import news7 from '../../../../image/news/news7.png'
import news8 from '../../../../image/news/news8.png'
import { useHistory } from 'react-router-dom';
import { id } from 'date-fns/locale';
import { useSelectorRoot } from '../../../../store/store';
import { BestPost } from '../../../../api/BestPost.api';
import SYSTEM_CONSTANTS from '../../../../common/constants';
const { Title, Text, Link } = Typography;

const LatestNews = () => {
  const history = useHistory();
  const {BestPostData} = useSelectorRoot(state => state.bestPost);
    // const dataSource = [
    //     {
    //         category: "Kiến thức",
    //         linkAll: "/kienthuc",
    //         firstNews: {
    //           id: 1,
    //             title: "Ngôn ngữ màu sắc tác động đến trẻ tự kỷ như thế nào",
    //                 content: "Lorem ipsum dolor sit amet consectetur. Pellentesque ullamcorper ut tincidunt justo vulputate egestas",
    //                 createBy: "Quản trị viên",
    //                 time: "01/08/2024",
    //                 image: news1
    //         },
    //         news: [
    //             {
    //               id:2,
    //                 title: "Làm việc nhóm cùng bạn",
    //                 content: "Lorem ipsum dolor sit amet consectetur.",
    //                 createBy: "Quản trị viên",
    //                 time: "01/08/2024",
    //                 image: news2
    //             },
    //             {
    //               id:3,
    //                 title: "Hoạt động ngoài trời và các tác động tích cực đến hành vi của trẻ",
    //                 content: "Lorem ipsum dolor sit amet consectetur.",
    //                 createBy: "Quản trị viên",
    //                 time: "01/08/2024",
    //                 image: news3
    //             },
    //             {
    //               id:4,
    //                 title: "Thực hành nhận biết và pha trộn màu sắc cùng cha mẹ",
    //                 content: "Lorem ipsum dolor sit amet consectetur. Pellentesque ullamcorper ut tincidunt justo vulputate egestas",
    //                 createBy: "Quản trị viên",
    //                 time: "01/08/2024",
    //                 image: news4
    //             },
    //         ]
    //     },
    //     {
    //         category: "CHIA SẺ",
    //         linkAll: "/chiase",
    //         firstNews: {
    //           id: 5,
    //             title: "Tâm sự cùng con",
    //                 content: "Lorem ipsum dolor sit amet consectetur. Pellentesque ullamcorper ut tincidunt justo vulputate egestas",
    //                 createBy: "Quản trị viên",
    //                 time: "01/08/2024",
    //                 image: news5
    //         },
    //         news: [
    //             {
    //               id:6,
    //                 title: "Làm việc nhóm cùng bạn",
    //                 content: "Lorem ipsum dolor sit amet consectetur.",
    //                 createBy: "Quản trị viên",
    //                 time: "01/08/2024",
    //                 image: news6
    //             },
    //             {
    //               id:7,
    //                 title: "Hoạt động ngoài trời và các tác động tích cực đến hành vi của trẻ",
    //                 content: "Lorem ipsum dolor sit amet consectetur.",
    //                 createBy: "Quản trị viên",
    //                 time: "01/08/2024",
    //                 image: news7
    //             },
    //             {
    //                 id:8,
    //                 title: "Thực hành nhận biết và pha trộn màu sắc cùng cha mẹ",
    //                 content: "Lorem ipsum dolor sit amet consectetur. Pellentesque ullamcorper ut tincidunt justo vulputate egestas",
    //                 createBy: "Quản trị viên",
    //                 time: "01/08/2024",
    //                 image: news8
    //             },
    //         ]
    //     }
    // ]
    const mapData = (data: BestPost[]) => {
      return data.filter(x => x.category?.data?.attributes?.Code != SYSTEM_CONSTANTS.hoatdong).map(item => ({
        category: item.category?.data?.attributes?.Title,
        linkAll: item.category?.data?.attributes?.Router,
        firstNews: item.posts?.data?.[0]? {
          title: item.posts?.data?.[0]?.attributes?.Title,
          content: item.posts?.data?.[0]?.attributes?.Content,
          createBy: item.posts?.data?.[0]?.attributes?.CreatedBy ?? "Quản trị viên",
          time: item.posts?.data?.[0]?.attributes?.publishedAt,
          image: SYSTEM_CONSTANTS.HOST + item.posts?.data?.[0]?.attributes?.Banner?.data?.attributes?.url,
          id: item.posts?.data?.[0]?.id
        }: null,
        news: item.posts?.data?.slice(1).map(item => ({
          title: item.attributes?.Title,
          content: item.attributes?.Content,
          createBy: item.attributes?.CreatedBy ?? "Quản trị viên",
          time: item.attributes?.publishedAt,
          image: SYSTEM_CONSTANTS.HOST + item.attributes?.Banner?.data?.attributes?.url,
          id: item.id
        })) ?? [],
        id: item.id
      }))
    }
    const [dataSource, setDataSource] = React.useState(mapData(BestPostData?.data?.map(x => ({...x.attributes,id: x.id})) ?? []));
    useEffect(() => {
      setDataSource(mapData(BestPostData?.data?.map(x => ({...x.attributes,id: x.id})) ?? []));
    }, [BestPostData])
    const TextWithMaxLength = (text: string, maxLength: number) => {
      const truncatedText = text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
      return <p>{truncatedText}</p>;
    };
  return (
    <div className="news-section">
        <div className="news-header">
        <Title level={4} style={{color: "#8D8D8D"}}>Tin tức</Title>
        </div>
      <div className="news-header">
        <Title level={1} style={{color: "#237F0A"}}>Tin nổi bật</Title>
        
        
      </div>
    {
        dataSource.map(item => (
            
      <div style={{marginBottom: 50}} key={item.id}>
      <Row gutter={[16, 16]} style={{justifyContent: 'space-between', padding: 8}}>
        <div className="category-tag" style={{fontSize: 20}}>{item.category}</div>
        <Link href={`/${item.linkAll}`} className="view-all" style={{fontSize: 20}}>Xem tất cả</Link>
        </Row>
        <Row gutter={[16, 16]}>
        {/* Left Side: Featured News */}
        <Col xs={24} sm={24} md={16} >
          {item.firstNews && <Card 
          className="featured-news" hoverable 
          cover={<img alt="news" src={item.firstNews.image} style={{maxHeight: 500}}/>} 
          onClick={() => {
            history.push('/news/'+  item?.linkAll + '/' +item.firstNews?.id);
        }}
          style={{height: 750}}>
            
            <Title level={4}>{item.firstNews.title}</Title>
            <Text type="secondary">Đăng bởi: {item.firstNews.createBy}</Text> <br />
            <Text type="secondary">{item.firstNews.time}</Text>
            <p>{item.firstNews.content}</p>
          </Card>
}
        </Col>

        {/* Right Side: Smaller Articles */}
        <Col xs={24} sm={24} md={8}>
        {
            item.news.map(tintuc => (
               
          <Card className="small-news" hoverable style={{height: 250}}
          onClick={() => {
            history.push('/news/'+ item?.linkAll + '/' +tintuc.id);
        }}
          >
            <Row gutter={16}>
              <Col span={8}>
                <img alt="news" src={tintuc.image} className="small-news-image" />
              </Col>
              <Col span={16}>
                <Title level={5}>{tintuc.title}</Title>
                <Text type="secondary">Đăng bởi: {tintuc.createBy}</Text> <br />
                <Text type="secondary">{tintuc.time}</Text>
                {window.innerWidth >= 1000 && TextWithMaxLength(tintuc.content ?? '', 100)}
              </Col>
            </Row>
          </Card>
            ))
        }
        </Col>
      </Row>
      </div>
      
    
        ))
    }
    </div>
  );
};

export default LatestNews;
