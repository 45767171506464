import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Pagination, QueryParams, ResponseData, RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError, map, of, OperatorFunction} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import BestPostAPI, { BestPost } from "../../api/BestPost.api";

interface BestPostState {
    loading: boolean;
    BestPostData: ResponseData<BestPost> | null
    errorMessage: string | null;
    pagination: Pagination | null | undefined;
}

const initState: BestPostState = {
    loading: false,
    BestPostData: null,
    errorMessage: null,
    pagination: null
};
const BestPostSlice = createSlice({
    name: 'BestPost',
    initialState: initState,
    reducers: {
        getBestPost(state, action: PayloadAction<void>) {
            state.loading = true;
        },
        getBestPostSuccess(state, action: PayloadAction<ResponseData<BestPost> | null>) {
            state.loading = false;
            state.BestPostData = action.payload;
            state.pagination = action.payload?.meta?.pagination;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const BestPost$: RootEpic = (action$) =>
    action$.pipe(
        filter(getBestPost.match),
        switchMap((re) => {
            return BestPostAPI.get().pipe(
                mergeMap((res) => {
                    return [
                        BestPostSlice.actions.getBestPostSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    BestPostSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const BestPostEpics = [BestPost$];
export const {
    getBestPost,
    getBestPostSuccess,
} = BestPostSlice.actions;
export const BestPostReducer = BestPostSlice.reducer;