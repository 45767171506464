import SYSTEM_CONSTANTS from '../common/constants';
import {AttributeData, Icon, IconAttributes, ResponseData } from '../common/define-types';
import HttpClient from './http-client';
import {AjaxError} from 'rxjs/ajax';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { Category } from './Categorys.api';
import { Tag } from './Tag.api';


export interface Post {
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  CreatedBy?: string;
  Title: string;
  Body: string;
  id: number;
  Content: string;
  Viewer: string;
  category?: {
    data?: {
        id: number;
        attributes: Category;
    }
  };
  tag?: {data?: AttributeData<Tag>};
  Images?: {data?: IconAttributes[]};
  Banner?: {data?: IconAttributes};
}

export default class PostsAPI {
  static host = SYSTEM_CONSTANTS.HOST;
  static get(param: {id?: string, category?: string, tag?: string}): Observable<ResponseData<Post> | null> {
      let api = `${PostsAPI.host}/${SYSTEM_CONSTANTS.Post.GET}?populate=category&populate=Banner&populate=Images&populate=tag`;
      if (param.category) {
          api += `&filters[category][Router][$eq]=${param.category}`;
      }
      if (param.id) {
          api += `&filters[id][$eq]=${param.id}`;
      }
      if (param.tag) {
          api += `&filters[Tags][Code][$eq]=${param.tag}`;
      }
      return HttpClient.get(api, {
          headers: {
              'Content-Type': 'application/json; charset=utf-8',
          },
      }).pipe(
          map(
              res => (res as ResponseData<Post>) || null,
              catchError((e: AjaxError) => throwError(e)),
          ),
      );
  }
}
