const SYSTEM_CONSTANTS = {
    HOST:'https://admin.namkhoimedia.io.vn',
    hoatdong: 'hoadongnoibat',
    IDENTITY: {
        LOGIN: 'api/auth/local',
        FORGOT: 'users',
        PROFILE: 'api/user/me',
        ROLE: 'api/users-permissions/roles',
    },
    MENU: {
        GET: 'api/menus',
    },
    BestPost: {
        GET: 'api/best-posts',
    },
    Post: {
        GET: 'api/posts',
    },
    Child: {
        GET: 'api/children',
    },
    PRODUCTION: {
        GET: 'api/productions',
    },
    TAG: {
        GET: 'api/tags',
    },
    CATEGORY: {
        GET: 'api/categories',
    }
};

export default SYSTEM_CONSTANTS;
