import SYSTEM_CONSTANTS from '../common/constants';
import {AttributeData, Icon, ResponseData } from '../common/define-types';
import HttpClient from './http-client';
import {AjaxError} from 'rxjs/ajax';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { Category } from './Categorys.api';
import {Post} from './Post';


export interface BestPost {
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  Priority: string;
  id: number;
  category?: {data?: AttributeData<Category>};
  posts?: {data?: AttributeData<Post>[]};
}

export default class BestPostAPI {
  static host = SYSTEM_CONSTANTS.HOST;
  static get(): Observable<ResponseData<BestPost> | null> {
      let api = `${BestPostAPI.host}/${SYSTEM_CONSTANTS.BestPost.GET}?populate=category&populate=posts&populate=posts.Banner`;
      return HttpClient.get(api, {
          headers: {
              'Content-Type': 'application/json; charset=utf-8',
          },
      }).pipe(
          map(
              res => (res as ResponseData<BestPost>) || null,
              catchError((e: AjaxError) => throwError(e)),
          ),
      );
  }
}
