import SYSTEM_CONSTANTS from '../common/constants';
import {Icon, ResponseData } from '../common/define-types';
import HttpClient from './http-client';
import {AjaxError} from 'rxjs/ajax';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';


export interface Category {
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  Title: string;
  Code: string;
  id: number;
  Router: string;
}

export default class CategorysAPI {
  static host = SYSTEM_CONSTANTS.HOST;
  static get(): Observable<ResponseData<Category> | null> {
      let api = `${CategorysAPI.host}/${SYSTEM_CONSTANTS.CATEGORY.GET}`;
      return HttpClient.get(api, {
          headers: {
              'Content-Type': 'application/json; charset=utf-8',
          },
      }).pipe(
          map(
              res => (res as ResponseData<Category>) || null,
              catchError((e: AjaxError) => throwError(e)),
          ),
      );
  }
}
