import React, { useState } from 'react';
import { Menu, Button, Drawer } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import './Header.css';
import logo from '../../image/logo_cycle.png';

const Header = () => {
  const [visible, setVisible] = useState(false);
  const location = useLocation(); // Hook to get current location
// Define which menu item should be selected based on the current path
const getSelectedKeys = () => {
    const path = location.pathname;
    // Match the current path to the correct Menu key
    if (path.includes('/hoaxuyengchi')) return ['hoaxuyengchi'];
    if (path.includes('/chandung') || path.includes('/child')) return ['chandung'];
    if (path.includes('/kienthuc')) return ['kienthuc'];
    if (path.includes('/chiase')) return ['chiase'];
    if (path.includes('/hoatdong')) return ['hoatdong'];
    if (path.includes('/lienhe')) return ['lienhe'];
    return []; // Default case
  };
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const getMenuItems = () => {
    const menuItems = [
      { key: 'hoaxuyengchi', label: 'Hoa Xuyến Chi' },
      { key: 'chandung', label: 'Chân dung' },
      { key: 'kienthuc', label: 'Kiến thức' },
      { key: 'chiase', label: 'Chia sẻ' },
      { key: 'hoatdong', label: 'Hoạt động' },
    ];
    return menuItems.map((item) => (
      <Menu.Item key={item.key}>
        <Link to={`/${item.key}`}>{item.label}</Link>
      </Menu.Item>
    ));
  };
  return (
    <div className="header-container">
      <div className="header-menu-desktop">
        <Link to="/" style={{flex:1, display:'flex', alignItems:'center'}}>
            <img
              style={{ height: "60px" }}
              src={logo}
              alt="Symbiotic Info tech Pvt. Ltd"
              title="Symbiotic Info tech Pvt. Ltd"
            />
          </Link>
        <Menu mode="horizontal" className="header-menu" defaultSelectedKeys={['hoaxuyenchith']} selectedKeys={getSelectedKeys()}>
          {getMenuItems()}          
        </Menu>
        <div className="lienhe-btn">
            <Button type="primary" shape="round" size="large">Liên hệ</Button>
        </div>
      </div>

      {/* Hamburger menu for mobile view */}
      <div className="header-menu-mobile">
        <Button 
          className="menu-button" 
          type="text" 
          icon={<MenuOutlined />} 
          onClick={showDrawer}
        />
        <Drawer
          title="Menu"
          placement="right"
          onClose={onClose}
          visible={visible}
        >
            <Link to="/" style={{flex:1, display:'flex', alignItems:'center'}}>
            <img
              style={{ height: "60px" }}
              src={logo}
              alt="Symbiotic Info tech Pvt. Ltd"
              title="Symbiotic Info tech Pvt. Ltd"
            />
          </Link>
          <Menu mode="vertical" onClick={onClose} selectedKeys={getSelectedKeys()}>
            {getMenuItems()}
          </Menu>
          <div className="lienhe-btn-mobile">
            <Button type="primary" shape="round" size="large">Liên hệ</Button>
            </div>
        </Drawer>
      </div>
    </div>
  );
};

export default Header;