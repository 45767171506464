import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Pagination, QueryParams, ResponseData, RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError, map, of, OperatorFunction} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import ChildsAPI, { Child } from "../../api/Child.api";

interface ChildsState {
    loading: boolean;
    ChildsData: ResponseData<Child> | null
    Child: Child | null;
    errorMessage: string | null;
    pagination: Pagination | null | undefined;
}

const initState: ChildsState = {
    loading: false,
    Child: null,
    ChildsData: null,
    errorMessage: null,
    pagination: null
};
const ChildsSlice = createSlice({
    name: 'Childs',
    initialState: initState,
    reducers: {
        getChilds(state, action: PayloadAction<void>) {
            state.loading = true;
        },
        getChildsSuccess(state, action: PayloadAction<ResponseData<Child> | null>) {
            state.loading = false;
            state.ChildsData = action.payload;
            state.pagination = action.payload?.meta?.pagination;
        },
        getChild(state, action: PayloadAction<number>) {
            state.loading = true;
        },
        setChild(state, action: PayloadAction<Child | null>) {
            state.loading = false;
            state.Child = action.payload;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const Childs$: RootEpic = (action$) =>
    action$.pipe(
        filter(getChilds.match),
        switchMap((re) => {
            return ChildsAPI.get().pipe(
                mergeMap((res) => {
                    return [
                        ChildsSlice.actions.getChildsSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    ChildsSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const Children$: RootEpic = (action$) =>
    action$.pipe(
        filter(getChild.match),
        switchMap((re) => {
            return ChildsAPI.getChild(re.payload).pipe(
                mergeMap((res) => {
                    return [
                        ChildsSlice.actions.setChild(res?.data[0].attributes ?? null),
                    ];
                }),
                catchError((e: AjaxError) => [
                    ChildsSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const ChildsEpics = [Childs$, Children$];
export const {
    getChilds,
    getChildsSuccess,
    getChild,
    setChild,
} = ChildsSlice.actions;
export const ChildsReducer = ChildsSlice.reducer;