import { ConfigProvider } from 'antd';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css'
import Home from './pages/screen/home/home';
import PortraitGallery from './pages/screen/PortraitGallery/PortraitGallery';
import Activities from './pages/screen/activities/activities';
import Header from './pages/screen/Header';
import Child from './pages/screen/PortraitGallery/child/child';
import NewsDetail from './pages/screen/activities/NewsDetail/NewDetail';

export const App = () => (
  <ConfigProvider prefixCls="ant">
    <BrowserRouter basename="/">
      <Header />
      <div className="scroll-container"> {/* Add a scroll container */}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/chandung" component={PortraitGallery} />
        <Route path="/hoaxuyengchi" component={Home} />
        <Route path="/kienthuc" component={Activities} />
        <Route path="/hoatdong" component={Activities} />
        <Route path="/chiase" component={Activities} />
        <Route path="/Child" component={Child} />
        <Route path="/news" component={NewsDetail}/>
      </Switch>
      </div>
    </BrowserRouter>
  </ConfigProvider>
);
