import { Card, Typography } from "antd"
import anhbia from '../../../../image/anhbia.png';
const { Title } = Typography;
interface BannerProps {
    title: string
}
const Banner = (props: BannerProps) => {
    return (
        <>
            {/* Header Section */}
            <Card style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0 }} styles={{ body: { width: '100%', padding: 0 } }} >
                <img src={anhbia} alt="Group" style={{ width: '100%', height: 'auto', maxHeight: 300 }} />
                <div style={{ position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.4)', top: 0, left: 0, textAlign: 'center', width: '100%', height: "100%", alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                    <Title level={3} style={{ color: 'white' }}>{props.title}</Title>
                </div>
            </Card>
        </>
    )
}
export default Banner;