import SYSTEM_CONSTANTS from '../common/constants';
import { AttributeData, Icon, IconAttributes, QueryParams, ResponseData } from '../common/define-types';
import HttpClient from './http-client';
import {AjaxError} from 'rxjs/ajax';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { Category } from './Categorys.api';


export interface Tag {
  Name: string | null;
  Code: string;
  Description: string | null;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  Value: any | null; // Replace 'any' with the specific type if known
  id?: number;
  category?: {
    data: AttributeData<Category>
  };
}
export default class TagAPI {
  static host = SYSTEM_CONSTANTS.HOST;
  static get(): Observable<ResponseData<Tag> | null> {
      let api = `${TagAPI.host}/${SYSTEM_CONSTANTS.TAG.GET}?populate=Image&populate=category`;
      
      return HttpClient.get(api, {
          headers: {
              'Content-Type': 'application/json; charset=utf-8',
          },
      }).pipe(
          map(
              res => (res as ResponseData<Tag>) || null,
              catchError((e: AjaxError) => throwError(e)),
          ),
      );
  }
}
